import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import LoansStyles from './Loans.styled';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../Shared/Link';
import ButtonLink from '../Shared/ButtonLink';
import ArrowIcon from '../Shared/ArrowIcon';
import BiweeklyCalendarIcon from '../Shared/Icons/BiweeklyCalendarIcon';
import PortfolioKeyIcon from '../Shared/Icons/PortfolioKeyIcon';
import CreditHouseIcon from '../Shared/Icons/CreditHouseIcon';
import DollarLoanIcon from '../Shared/Icons/DollarLoanIcon';
import MortgageHouseIcon from '../Shared/Icons/MortgageHouseIcon';

const defaultLink = {
  to: '/home-owning/',
  title: 'View all',
};

const defaultViewAllLink = {
  to: '/payment-options/',
};

const loans = [
  {
    title: 'New Manufactured Home Loan',
    icon: <PortfolioKeyIcon />,
    to: '/portfolio-home-loan/',
  },
  {
    title: 'Less Than Perfect Credit',
    icon: <CreditHouseIcon />,
    to: '/fresh-start-home-loan/',
  },
  {
    title: 'Biweekly Payment Loan',
    icon: <BiweeklyCalendarIcon />,
    to: '/biweekly-mortgage/',
  },
  {
    title: 'Used Home Loan',
    icon: <DollarLoanIcon />,
    to: '/direct-lending/',
  },
  {
    title: 'Land & Home Mortgage',
    icon: <MortgageHouseIcon />,
    to: '/land-home-mortgage/',
  },
];

const Loans = ({
  link = defaultLink,
  viewAllLink = defaultViewAllLink,
  isAnimated = true,
  mobileTopPadding = '4.125rem',
  tabletTopPadding = '8.0625rem',
  desktopTopPadding = '10.8125rem',
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.25 });

  return (
    <LoansStyles
      mobileTopPadding={mobileTopPadding}
      tabletTopPadding={tabletTopPadding}
      desktopTopPadding={desktopTopPadding}
      ref={ref}
      className={`block-loans ${isAnimated ? 'isAnimated' : ''} ${
        inView ? 'active' : ''
      }`}
    >
      <div className='inner'>
        <div className='cta'>
          <h2 className='type-h1'>Loans We Offer</h2>
          <div className='payment_options'>
            <h3 className='type-h5'>Looking to make a payment?</h3>
            {link && link.to && link.title && (
              <Link {...link}>{link.title}</Link>
            )}
          </div>
          {viewAllLink && viewAllLink.to && viewAllLink.title && (
            <ButtonLink {...viewAllLink} appearance='dark' size='large'>
              {viewAllLink.title}
            </ButtonLink>
          )}
        </div>
        <ul className='loans'>
          {loans.map(({ title, icon, to, ...loan }, i) => (
            <li
              key={i}
              className='loan'
              style={{ transitionDelay: `${i * 0.2 + 0.8}s` }}
            >
              <RouterLink to={to} {...loan}>
                {icon}
                <h4>{title}</h4>
                <ArrowIcon />
              </RouterLink>
            </li>
          ))}
        </ul>
      </div>
    </LoansStyles>
  );
};

Loans.propTypes = {
  link: PropTypes.oneOfType([
    PropTypes.shape({
      to: PropTypes.string,
      isExternal: PropTypes.bool,
      newTab: PropTypes.bool,
    }),
    PropTypes.bool,
  ]),
  isAnimated: PropTypes.bool,
};

export default Loans;
