import React from 'react';

export default (width = 80, height = 80) => (
  <svg
    width={48}
    height={48}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <rect width={48} height={48} fill='url(#pattern0_11071_19416)' />
    <defs>
      <pattern
        id='pattern0_11071_19416'
        patternContentUnits='objectBoundingBox'
        width={1}
        height={1}
      >
        <use xlinkHref='#image0_11071_19416' transform='scale(0.0078125)' />
      </pattern>
      <image
        id='image0_11071_19416'
        width={128}
        height={120}
        preserveAspectRatio='none'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAAAS5AAAEuQER4c0nAAADvUlEQVR4nO3dv08TYRzH8W/L0QISbBATAyH+GFSQBIjC4KDuGlF3nRxMHHXUqMFIoqMhcdDBf0AXnUwcGEyEIGqU1EUGFxeNIKiF2rqRtjfI5e6ea/t5vyZ6yT3PhbzpNfDNkSqXy+ZCdnCkdqNbhcW3N51sXueygyM3zexG5bHC4tuUi73TLjZB/SIAcQQgjgDEEYA4AhBHAOIIQBwBiPOygyMnEtp7T4J715s9tQdcfW9SmYFhN78LRl3iFiCOAMQRgDiv9sDZk6N27tRoEteCmD15tmBPny9UHfMF0Nebs/HDe51dFNx5/WbJd4xbgDgCEEcA4ghAnO9DYBxWNj5b/sdDF1s1jYO5i9bVui/2fZwEUCyt2ffCBxdbNY1iac3JPtwCxBGAOCe3AC+9zbqzQy62ahpeepubfVxs0tW6z8Z33nGxFQLiFiCOAMQRgDgCEOf7EDg7v2T37WUS14KYzc77/xzMUKg4bgHiCEAcAYjzfQi8dvmSXb98KYlrQcwmpx/Y7ekHVcd4BxBHAOIIQBwBiAv85+B3+U92depu6I3Pn5mwC2dPb77++vtV6DUz6e3WnT0U6ZpmZrvaj25+/b3w0dZLy5GuubrxxVaLX0Kv2Z0dsky6K9A5gQNYXvlpM3PzQU/zOTY+VvX6z99vodesFcea66XlyNctln9FsmapvBH4HG4B4ghAHAGIIwBxBCCOAMQRgDgCEEcA4ghAHAGIIwBxBCCOAMQRgLjA8wC7+3rtWgRTw8fHjlS9zmX2h17TS3VEvmatTq/f2lp2RLpmW0uP5TLh1/FS7cHPCXrC7r7eWMbGc5kDDbFmZ2t/5Gu2teyIPKqt4hYgLvA7wHppJZJHvnV6/VU/TcwENshMYKm8Ecn8Wu1bHjOBzAQiAQQgjgDEEYA4AhBHAOIIQBwBiCMAcQQgjgDEEYA4AhBHAOIIQFzgeQAv1R7JrF1bS0/Va2YCw6/jZCbQS3c0zPweM4H/xy1AHDOBW1A5v3dl6p69z+dDr/ni8aPNr5kJtMaZCXyfz0fynMRKzAQiMQQgjgDEEYA4AhBHAOIIQBwBiCMAcQQgjgDEEYA4AhBHAOIIQBwzgQGdPzPh+5+HYTETaI0zE1j5306jwkwgEsNM4BbwnMAK6jOBPCcQTYUAxBGAOAIQRwDiCEAcAYgjAHEEII4AxBGAOAIQRwDiCEAcAYhjJjAgnhMoPhPIcwLRVAhAHAGI830GmJmds8kkrgSxm5md8x1LZQaGywlcC+oEtwBxBCCOAMT9A2VVFojnlgc4AAAAAElFTkSuQmCC'
      />
    </defs>
  </svg>
);
